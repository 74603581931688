import { login, logout } from '../../api/user'
import Cookies from "js-cookie";

const state = {
  seniorData: JSON.parse(localStorage.getItem('seniorData')) || {},
  token: JSON.parse(localStorage.getItem('token')) || '',
  user: JSON.parse(localStorage.getItem('user')) || {},
  numberOfLoginError: localStorage.getItem('errorNumber') || 0,
  addressList: JSON.parse(localStorage.getItem('addressList')) || {}

}

const mutations = {
  SET_SENIOR_DATA(state, data) {
    state.seniorData = data
    localStorage.setItem('seniorData', JSON.stringify(data))
  },
  SET_TOKEN(state, token) {
    state.token = token
    localStorage.setItem('token', JSON.stringify(token))
  },
  SET_USER(state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  SET_ERROR_NUMBER(state, number) {
    state.numberOfLoginError = number
    localStorage.setItem('errorNumber', number)
  },
  SET_ADDRESS_LIST(state, payload) {
    if (!state.addressList[payload.question]) {
      state.addressList[payload.question] = payload.address
    }
    localStorage.setItem('addressList', JSON.stringify(state.addressList))
  },
  clearAddress(state, payload) {
    state.addressList = payload
    localStorage.setItem('addressList', JSON.stringify(state.addressList))
  }
}

const actions = {
  // 登录
  async login({ commit }, payload) {
    const res = await login(payload)
    if (res.isRmpsd) {
      Cookies.set("username", res.userName, {
        expires: 1,
      });
      Cookies.set("password", res.mD5Psd, {
        expires: 1,
      });
      Cookies.set("isRmpsd", res.isRmpsd, {
        expires: 1,
      });
    } else {
      Cookies.remove("username");
      Cookies.remove("password");
      Cookies.remove("isRmpsd");
    }
    commit('SET_TOKEN', res.token)
    commit('SET_USER', res)
  },
  async setUser({ commit }, payload) {
    commit('SET_TOKEN', payload.token)
    commit('SET_USER', payload)
  },
  async logout({ commit }, payload) {
    if (!payload) {
      await logout()
    }
    commit('SET_TOKEN', null)
    commit('SET_USER', {})
    commit('clearAddress', {})
  }
}


export default {
  namespaced: true,
  state,
  actions,
  mutations
}
