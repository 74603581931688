<template>
  <div class="header">
    <div class="wrap nav">
      <div class="left" @click="$router.push('/')">
        <img class="img" src="../assets/new_logo.png" />
      </div>
      <div class="right">
        <ul @click="onClick">
          <!-- <li :class="{ active: $route.name === 'Index' }" :data-index="0">
            首页
          </li> -->
          <!-- <li :data-index="1" :class="{ active: $route.name === 'Home' }">
            迁徙图
          </li> -->
          <!-- <li></li>
          <template v-if="!token">
            <li :data-index="2" :class="{ active: currentIndex === 2 }">
              登录
            </li>
            <li :data-index="3" :class="{ active: currentIndex === 3 }">
              注册
            </li>
          </template>
          <template v-else>
            <li :data-index="4" :class="{ active: currentIndex === 4 }">
              {{ name }}
            </li>
            <li :data-index="5" :class="{ active: currentIndex === 5 }">
              注销
            </li>
          </template> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    ...mapActions("user", ["logout"]),
    onClick(e) {
      if (e.target.nodeName === "LI") {
        this.currentIndex = Number(e.target.dataset.index);
        switch (Number(e.target.dataset.index)) {
          case 0:
            this.$router.push("/");
            break;
          case 1:
            this.$router.push({ name: "Home" });
            break;
          // case 2:
          //   this.$router.push({ name: "Login" });
          //   break;
          // case 3:
          //   this.$router.push({ name: "Register" });
          //   break;
          // case 4:
          //   break;
          // case 5:
          //   this.$confirm("是否退出登录？", "提示", {
          //     confirmButtonText: "确定",
          //     cancelButtonText: "取消",
          //     type: "warning",
          //   })
          //     .then(() => {
          //       this.logout();
          //       this.$router.push(`/login?redirect=${this.$route.fullPath}`);
          //       this.$message.success("操作成功");
          //     })
          //     .catch(() => {
          //       this.$message.info("取消");
          //     });
          //   break;
        }
      }
    },
  },
  computed: {
    name() {
      return this.$store.getters.name;
    },
    token() {
      return this.$store.getters.token;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 65px;
  background: #3b3b3b;
  display: flex;
  color: #fff;
  background: url("../assets/banner.png");
  background-size: 100% 100%;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      cursor: pointer;
      display: flex;
      align-items: center;
      .img {
        height: 50px;
        margin-right: 10px;
      }
      .text {
      }
    }
    .right {
      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        li:nth-child(3) {
          width: 2px;
          height: 30px;
          margin-left: 20px;
          background: #ccc;
        }

        li {
          margin-left: 40px;
          cursor: pointer;
        }
        li:nth-child(4) {
          margin-left: 20px;
        }
      }
    }
  }
  .active {
    background: #fff;
    color: #000;
    width: 70px;
    line-height: 35px;
    border-radius: 15px;
    text-align: center;
  }
}
</style>