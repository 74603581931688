<template>
  <div id="app">
    <m-header v-if="$route.meta.hidden"></m-header>
    <router-view />
  </div>
</template>

<script>
import mHeader from "../src/components/headerPage";
export default {
  components: {
    mHeader,
  },
  created() {
    // console.log(this.$route);
  },
};
</script>
<style lang="scss">
@import "./assets/css/reset.css";
// ::v-deep .el-tooltip__popper,
// .is-dark {
//   width: 500px;
// }
</style>
