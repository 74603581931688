import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import { Message } from 'element-ui'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   component: () => import('@/views/index'),
  //   meta: {
  //     hidden: true
  //   }
  // },
  {
    path: '/migrate',
    name: 'Migrate',
    component: () => import('@/views/home'),
    meta: {
      hidden: true
    }
  },
  {
    path: '/robotmigrate',
    name: 'RobotMigrate',
    component: () => import('@/views/home/robotmigrate'),
    meta: {
      hidden: false
    }
  }, {
    path: '/drilling',
    name: 'drilling',
    component: () => import('@/views/mapDrillingIn'),
    meta: {
      hidden: true
    }
  },
  {
    path: '/proofread/:id',
    name: 'proofread',
    component: () => import('@/views/proofread/singlePage'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/index/index'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/oldRecords',
    name: 'oldRecords',
    component: () => import('@/views/oldRecords/index'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/tree/:id',
    name: 'tree',
    component: () => import('@/views/tree/browsetree.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/treeMobile/:id',
    name: 'tree',
    component: () => import('@/views/tree/browsetree-mobile.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/journal',
    name: 'journal',
    component: () => import('@/views/journal/index'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/Result',
    name: 'Result',
    component: () => import('@/views/literature/index'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/historical',
    name: 'Historical',
    component: () => import('@/views/historical/index.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/ebook',
    name: 'Ebook',
    component: () => import('@/views/ebook/index.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/imgMobile',
    name: 'ImgMobile',
    component: () => import('@/views/ebook/imgMobile.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/genealogy',
    name: 'Genealogy',
    component: () => import('@/views/genealogy/index.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/ancestralGenealogy',
    name: 'AncestralGenealogy',
    component: () => import('@/views/home/ancestralGenealogy.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/ancestralGenealogyMobile',
    name: 'AncestralGenealogyMobile',
    component: () => import('@/views/home/genealogyMobile.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/genealogyInfo',
    name: 'GenealogyInfo',
    component: () => import('@/views/genealogyInfo/index.vue'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/ebook2',
    name: 'ebook2',
    component: () => import('@/views/ebook2'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/searchResult',
    name: 'searchResult',
    component: () => import('@/views/searchResult'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/overseaschinese',
    name: 'overseaschinese',
    component: () => import('@/views/overseaschinese'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/overRes',
    name: 'overRes',
    component: () => import('@/views/overRes'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/overBook',
    name: 'overBook',
    component: () => import('@/views/overBook'),
    meta: {
      hidden: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register')
  },
  { path: '/loseEfficacy', component: () => import('@/views/loseEfficacy.vue') },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/index')
  },
  {
    path: '/clearData',
    name: 'clearData',
    component: () => import('@/views/clearData')
  },
  {
    path: '/surname',
    name: 'surname',
    component: () => import('@/views/surname')
  },
  { path: '/404', component: () => import('@/views/404.vue') },
  { path: '*', redirect: '/404' }
]


const router = new VueRouter({
  routes
})

// 智能问答 登录判断
router.beforeEach((to, form, next) => {
  if (to.query.params) {
    let decodedParamsString = decodeURIComponent(to.query.params)
    store.dispatch("user/setUser", JSON.parse(decodedParamsString));
    // store.dispatch("user/setUserName", {surname: to.query.nickName});
  }
  if (to.path === '/robotmigrate') {
    if (!store.state.user.token) {
      Message.info('请先登录')
      next({ path: '/login' })
    } else {
      next()
    }
  } else if (to.path === '/login' && store.state.user.token) {
    // console.log(form.path)
    // next({ path: form.path })
    next({ path: '/robotmigrate' })
  } else {
    next()
  }
})

export default router

