/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
    const valid_map = ['admin', 'editor']
    return valid_map.indexOf(str.trim()) >= 0
}

/**
 * 手机号正则验证
 * @param str 手机号
 * @returns {boolean}
 */
export function validMobile(str) {
    return /^1[3-9]\d{9}$/.test(str)
}

export function validConfirmPassword(pwd, pwd1) {
    console.log(pwd, pwd1)
    return pwd === pwd1
}

export function validEmail(email) {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
}


