import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'
import '@/assets/css/public.css'
import '@/icons'
import './directive'
// 引入自定义图标
import './assets/icon_font/iconfont.css';
import iconPicker from 'e-icon-picker';
import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库

// 1. 引入你需要的组件
import { Button, Icon, Uploader, ImagePreview, List, PullRefresh, Grid, GridItem, Image as VanImage, Lazyload, ActionSheet, Empty, Sticky } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
// 3. 注册你需要的组件
Vue.use(Button);
Vue.use(Icon);
Vue.use(Uploader);
Vue.use(ImagePreview);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(VanImage);
Vue.use(ActionSheet);
Vue.use(Empty);
Vue.use(Sticky);
// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(iconPicker, { FontAwesome: true });


import { request } from './utils/request2.js'
import $ from 'jquery'
Vue.prototype.$request = request
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
