// directives.js
import Vue from 'vue'

// v-dialogdrag: 弹窗拖拽   Vue.directive    vue自定义指令
Vue.directive('dialogdrag', {
    bind(el, binding, vnode) {
        const dialogHeaderEl = el.querySelector('.el-dialog__header')
        const dragDom = el.querySelector('.el-dialog')
        dialogHeaderEl.style.cssText += ';cursor:move;'
        dragDom.style.cssText += ';top:0px;'

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const getStyle = (function () {
            if (window.document.currentStyle) {
                return (dom, attr) => dom.currentStyle[attr]
            } else {
                return (dom, attr) => getComputedStyle(dom, false)[attr]
            }
        })()

        dialogHeaderEl.onmousedown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderEl.offsetLeft
            const disY = e.clientY - dialogHeaderEl.offsetTop

            const dragDomWidth = dragDom.offsetWidth
            const dragDomheight = dragDom.offsetHeight

            const screenWidth = document.body.clientWidth
            const screenHeight = document.body.clientHeight

            const minDragDomLeft = dragDom.offsetLeft
            const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

            const minDragDomTop = dragDom.offsetTop
            const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight

            // 获取到的值带px 正则匹配替换
            let styL = getStyle(dragDom, 'left')
            let styT = getStyle(dragDom, 'top')

            if (styL.includes('%')) {
                styL = +document.body.clientWidth * (+styL.replace(/%/g, '') / 100)
                styT = +document.body.clientHeight * (+styT.replace(/%/g, '') / 100)
            } else {
                styL = +styL.replace(/\px/g, '')
                styT = +styT.replace(/\px/g, '')
            }

            document.onmousemove = function (e) {
                // 通过事件委托，计算移动的距离
                let left = e.clientX - disX
                let top = e.clientY - disY

                // 边界处理
                if (-(left) > minDragDomLeft) {
                    left = -minDragDomLeft
                } else if (left > maxDragDomLeft) {
                    left = maxDragDomLeft
                }

                if (-(top) > minDragDomTop) {
                    top = -minDragDomTop
                } else if (top > maxDragDomTop) {
                    top = maxDragDomTop
                }

                // 移动当前元素
                dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`

                // emit onDrag event
                vnode.child.$emit('dragDialog')
            }

            document.onmouseup = function () {
                document.onmousemove = null
                document.onmouseup = null
            }
        }
    }
})

// Vue.directive('dragscroll', {
//     inserted(el) {
//         el.onmousedown = function (ev) {
//             const disX = ev.clientX;
//             const disY = ev.clientY;
//             const originalScrollLeft = el.scrollLeft;
//             const originalScrollTop = el.scrollTop;
//             const originalScrollBehavior = el.style['scroll-behavior'];
//             const originalPointerEvents = el.style['pointer-events'];
//             el.style['scroll-behavior'] = 'auto';
//
//             document.onmousemove = function (ev) {
//                 ev.preventDefault();
//                 const distanceX = ev.clientX - disX;
//                 const distanceY = ev.clientY - disY;
//                 el.scrollTo(originalScrollLeft - distanceX, originalScrollTop - distanceY);
//                 el.style['pointer-events'] = 'none';
//             };
//             document.onmouseup = function () {
//                 document.onmousemove = null;
//                 document.onmouseup = null;
//                 el.style['scroll-behavior'] = originalScrollBehavior;
//                 el.style['pointer-events'] = originalPointerEvents;
//             };
//         };
//     }
// })

Vue.directive('dragscroll', {
    bind(el) {
        el.onMouseDownHandler = function (ev) {
            const disX = ev.clientX;
            const disY = ev.clientY;
            const originalScrollLeft = el.scrollLeft;
            const originalScrollTop = el.scrollTop;
            const originalScrollBehavior = el.style['scroll-behavior'];
            const originalPointerEvents = el.style['pointer-events'];
            el.style['scroll-behavior'] = 'auto';

            document.onmousemove = function (ev) {
                ev.preventDefault();
                const distanceX = ev.clientX - disX;
                const distanceY = ev.clientY - disY;
                el.scrollTo(originalScrollLeft - distanceX, originalScrollTop - distanceY);
                el.style['pointer-events'] = 'none';
            };

            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
                el.style['scroll-behavior'] = originalScrollBehavior;
                el.style['pointer-events'] = originalPointerEvents;
            };
        };

        el.addEventListener('mousedown', el.onMouseDownHandler);
    },
    unbind(el) {
        el.removeEventListener('mousedown', el.onMouseDownHandler);
    }
});


// Vue.directive('resize', {
//     bind(el, binding) {
//
//         // 初始化缩放比例
//         el.zoomScale = 1;
//
//         // 处理滚轮事件
//         el.onWheel = function (event) {
//             // 阻止默认的滚轮事件
//             event.preventDefault();
//             // if (binding.value) {
//             const newWidth = el.clientWidth * el.zoomScale;
//             const parentWidth = el.parentElement.clientWidth;
//             // 判断图片的新宽度是否超过父容器的宽度
//             // if (newWidth >= parentWidth && event.deltaY < 0) {
//             //     return; // 如果是，则不再放大
//             // }
//             // }
//
//             // 根据滚轮方向来调整缩放比例
//             el.zoomScale += event.deltaY > 0 ? -0.1 : 0.1;
//
//             // 设置图片样式
//             el.style.transform = `scale(${el.zoomScale})`;
//         };
//
//         // 监听滚轮事件
//         el.addEventListener("wheel", el.onWheel);
//     },
//     unbind(el) {
//         // 在组件销毁时解绑滚轮事件监听
//         el.removeEventListener("wheel", el.onWheel);
//     },
//
// })

Vue.directive('resize', {
    bind(el, binding) {
        // 初始化缩放比例
        el.zoomScale = 1;

        // 处理滚轮事件
        el.onWheel = function (event) {
            // 阻止默认的滚轮事件
            event.preventDefault();

            // 计算新的缩放比例
            const newZoomScale = el.zoomScale + (event.deltaY > 0 ? -0.1 : 0.1);

            // 限制缩放比例
            const minScale = 0.5;
            const maxScale = 5;
            if (newZoomScale < minScale || newZoomScale > maxScale) {
                return;
            }

            // 检查是否超过父容器的宽度
            if (binding.value) {
                const newWidth = el.clientWidth * newZoomScale;
                const parentWidth = el.parentElement.clientWidth;
                if (newWidth >= parentWidth && event.deltaY < 0) {
                    return; // 如果超过，则不再放大
                }
            }

            // 更新缩放比例
            el.zoomScale = newZoomScale;

            // 设置图片样式
            el.style.transition = 'transform 0.2s'; // 平滑过渡效果
            el.style.transform = `scale(${el.zoomScale})`;
        };

        // 监听滚轮事件
        el.addEventListener('wheel', el.onWheel);
    },
    update(el, binding,vnode,oldVnode) {
        el.zoomScale = 1;
        el.style.transform = `scale(${el.zoomScale})`;
    },
    unbind(el) {
        // 在组件销毁时解绑滚轮事件监听
        el.removeEventListener('wheel', el.onWheel);
    },

});


