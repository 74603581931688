import request from '../utils/request'

// 获取手机验证码
export const getPhoneCode = data => {
    return request({
        method: 'POST',
        url: '/userrole/telephone-code/send-msg',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}

// 注册
export const register = data => {
    return request({
        method: 'POST',
        url: '/userrole/user/phone-register',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}

// 登录
export const login = data => {
    return request({
        method: 'POST',
        url: '/userrole/user/login',
        data,
        params: {
            loginFromType: data.loginFromType
        }
    })
}

// 登出
export const logout = () => {
    return request({
        method: 'POST',
        url: '/userrole/user/login-out',
    })
}

// // 获取用户信息
// export const getUserInfo = () => {
//     return request({
//         method: 'GET',
//         url: '/api/userrole/user/login-user'
//     })
// }

// 修改账户
export const updateUser = (data) => {
    return request({
        method: 'PUT',
        url: '/userrole/user',
        data
    })
}

// 修改密码
export const updatePassword = (data) => {
    return request({
        method: 'POST',
        url: '/userrole/user/update-password',
        data
    })
}

// 获取消息
export const getMsg = (params) => {
    return request({
        method: 'GET',
        url: '/userrole/g-pTUser-message/g-pTUser-message-list',
        params
    })
}

// 读取消息
export const readMsg = id => {
    return request({
        method: 'GET',
        url: `/userrole/g-pTUser-message/${id}/g-pTUser-message-by-id`,
    })
}

// 查询账号有几个客户端登录
export const checkUserAccountCount = () => {
    return request({
        method: 'POST',
        url: `/userrole/user/check-user-account-count`,
    })
}

// 发送邮箱验证码
export const sendEmail = data => {
    return request({
        method: 'POST',
        url: `/userrole/e-mail/send-email`,
        data
    })

    // 邮箱注册
}
export const emailReg = data => {
    return request({
        method: 'POST',
        url: `/userrole/user/email-register`,
        data
    })
}

// 中奖记录

export const getJackPot = params => {
    return request({
        method: 'GET',
        url: `/userrole/jackpot-message`,
        params
    })
}

// 获取联系人电话
export const GetPhoneNumber = params => {
    return request({
        method: 'GET',
        url: `/userrole/ancient-place-contracts/ancient-place-contracts-by-area`,
        params
    })
}
// 兌獎
export const redeemJackpot = id => {
    return request({
        method: 'POST',
        url: `/userrole/jackpot-message/redeem-jackpot/${id}`
    })
}






