import axios from 'axios'
import store from '@/store'
import route from '@/router'
import { Message } from 'element-ui'
import Cookies from "js-cookie";

//axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export function request(config) {
    const instance = axios.create({
        baseURL: '/',
        timeout: 15000,
        withCredentials: true
    });

    //请求拦截器
    instance.interceptors.request.use(
        config => {
            if (store.state.user.token) {
                config.headers['Authorization'] = store.state.user.token
            }
            return config
            return config;
        },
        error => {
            tryHideFullScreenLoading()
            Promise.reject(error)
        }
    )

    instance.interceptors.response.use(
        response => {
            // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
            // 否则的话抛出错误
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            else {
                return Promise.resolve(response);
            }
        },
        // 根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
        async error => {
            if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
                console.log("错误回调", error);
                Message({
                    message: '请求超时',
                    duration: 2000,
                    forbidClick: true
                });
                return Promise.reject(error); // reject这个错误信息
            }
            if (error.response.status) {
                switch (error.response.status) {
                    case 401:
                        Message({
                            message: '登录过期，请重新登录',
                            duration: 2000,
                            forbidClick: true
                        })
                        // 退出登录
                        console.log(route)
                        await store.dispatch('user/logout', true)
                        Cookies.remove("username");
                        Cookies.remove("password");
                        Cookies.remove("isRmpsd");
                        route.push(`/login?redirect=${route.currentRoute.query.redirect ? route.currentRoute.query.redirect : route.currentRoute.fullPath}`)
                        break;
                    case 403:
                        Message({
                            message: error.response.data.error.message,
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 404:
                        Message({
                            message: '网络请求不存在',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 405:
                        Message({
                            message: 'Method not allowed',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 500:
                        Message({
                            message: '服务器异常',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    default:
                        Message({
                            message: error.response.data.error.message,
                            duration: 1500,
                            forbidClick: true
                        })
                }
                return Promise.reject(error.response);
            }
        }
    );
    return instance(config)
}
